import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { StepsContextProvider } from "@contexts/steps-context/steps-context";
import "./App.css";

const BillingInformation = lazy(() => import("@components/steps-section/billing-information-section/billing-information"));
const Contract = lazy(() => import("@components/steps-section/contract-section/contract"));
const DomainName = lazy(() => import("@components/steps-section/domain-name-section/domain-name"));
const JoinTheClub = lazy(() => import("@components/join-the-club-section/join-the-club"));
const LayoutForSteps = lazy(() => import("@components/steps-section/layout-for-steps"));
const SearchYourBusiness = lazy(() => import("@components/steps-section/search-your-business-section/search-your-business"));
const SocialMedia = lazy(() => import("@components/steps-section/social-media-section/social-media"));
const Subscription = lazy(() => import("@components/steps-section/subscription-section/subscription"));
const Summary = lazy(() => import("@components/steps-section/summary-section/summary"));
const WelcomeOnBoard = lazy(() => import("@components/welcome-on-board-section/welcome-on-board"));
const Payment = lazy(()=> import("@components/steps-section/payment-section/payment"))

function App() {
  
  return (
    <StepsContextProvider>
      <Router>
        <Suspense fallback={<div>Caricamento in corso...</div>} >
          <Routes>
            <Route path="/" element={<JoinTheClub />} />
            <Route element={<LayoutForSteps />}>
              <Route
                path="/search-your-business"
                element={<SearchYourBusiness />}
              />
              <Route path="/social-media" element={<SocialMedia />} />
              <Route path="/domain-name" element={<DomainName />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/summary" element={<Summary />} />
              <Route path="/billing-information" element={<BillingInformation />} />
              <Route path="/contract" element={<Contract />} />
              <Route path="/payment" element={<Payment/>}/>
            </Route>
            <Route path="/welcome-on-board" element={<WelcomeOnBoard />} />
          </Routes>
        </Suspense>
      </Router>
    </StepsContextProvider >
  );
}

export default App;
