import React, { createContext, useState, ReactNode } from 'react';

interface StepsContextInterface {
  restaurantInfo: null | string;
  setRestaurantInfo: (value: null | string) => void;
  searchYourBusinessPopUpOpen: boolean;
  setSearchYourBusinessPopUpOpen: (value: boolean) => void;
  socialMediaPopUpOpen: boolean;
  setSocialMediaPopUpOpen: (value: boolean) => void;
  planType: string;
  setPlanType: (value: string) => void;
  annualPlanPrice: number;
  setAnnualPlanPrice: (value:number) => void;
  monthlyPlanPrice: number;
  setMonthlyPlanPrice: (value:number) => void;
  packages: string[];
  setPackages: (value:string[]) => void;
  submitForm: (() => void) | null;
  setSubmitForm: (submitForm: (() => void) | null) => void;
  restaurantInfoForOrder: object;
  setRestaurantInfoForOrder: (value: object) => void;
  authToken: undefined | string;
  setAuthToken: (value: undefined | string) => void;
  userId: string;
  setUserId: (value: string) => void;
  socialPages: [any];
  domainName: string;
  setDomainName: (value: string) => void;
  setSocialPages: (value: object) => void;
  getCookie: (name: string) => string | null;
}

export const StepsContext = createContext<StepsContextInterface>({
  restaurantInfo: "",
  setRestaurantInfo: () => {},
  searchYourBusinessPopUpOpen: false,
  setSearchYourBusinessPopUpOpen: () => {},
  socialMediaPopUpOpen: false,
  setSocialMediaPopUpOpen: () => {},
  planType: "annual",
  setPlanType: () => {},
  annualPlanPrice: 220,
  setAnnualPlanPrice: () => {},
  monthlyPlanPrice: 250,
  setMonthlyPlanPrice: () => {},
  packages: [],
  setPackages: () => [],
  submitForm: null,
  setSubmitForm: () => {},
  restaurantInfoForOrder: {},
  setRestaurantInfoForOrder: () => {},
  authToken: "",
  setAuthToken: () => {},
  userId: "",
  setUserId: () => {},
  socialPages: [{}],
  setSocialPages: () => {},
  domainName: "",
  setDomainName: () => {},
  getCookie: () => ""
});

export const StepsContextProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [restaurantInfo, setRestaurantInfo] = useState<null | string>(null);
  const [searchYourBusinessPopUpOpen, setSearchYourBusinessPopUpOpen] = useState<boolean>(false);
  const [socialMediaPopUpOpen, setSocialMediaPopUpOpen] = useState<boolean>(false);
  const [planType, setPlanType] = useState<string>("annual");
  const [annualPlanPrice, setAnnualPlanPrice] = useState<number>(220);
  const [monthlyPlanPrice, setMonthlyPlanPrice] = useState<number>(250);
  const [packages, setPackages] = useState<string[]>([]);
  const [submitForm, setSubmitForm] = useState<(() => void) | null>(null);
  const [restaurantInfoForOrder, setRestaurantInfoForOrder] = useState<any>();
  const [authToken, setAuthToken] = useState<undefined | string>("");
  const [userId, setUserId] = useState<string>("");
  const [socialPages, setSocialPages] = useState<any>([]);
  const [domainName, setDomainName] = useState<string>("");

  const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      const lastPart = parts.pop();
      if (lastPart) {
        return lastPart.split(';').shift() || null;
      }
    }
    return null;
  };

  const value = {
    restaurantInfo,
    setRestaurantInfo,
    searchYourBusinessPopUpOpen,
    setSearchYourBusinessPopUpOpen,
    socialMediaPopUpOpen,
    setSocialMediaPopUpOpen,
    planType,
    setPlanType,
    annualPlanPrice,
    setAnnualPlanPrice,
    monthlyPlanPrice,
    setMonthlyPlanPrice,
    packages,
    setPackages,
    submitForm,
    setSubmitForm,
    restaurantInfoForOrder,
    setRestaurantInfoForOrder,
    authToken, 
    setAuthToken,
    userId, 
    setUserId,
    socialPages,
    setSocialPages,
    domainName,
    setDomainName,
    getCookie
  };
  
  return (
    <StepsContext.Provider value={ value }>
      {children}
    </StepsContext.Provider>
  );
};
