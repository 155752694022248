// keycloak.js
import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: "https://account.restaurants.club",
  realm: "develop",
  clientId: "develop-front-rc"
});

export default keycloak;
